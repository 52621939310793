export default {
	route: {
	  rootMenu: "Root Menu",
	  home:"Home",
	  dashboard: 'Home',
	  form: 'Form',
	  baseForm: 'Base Form',
	  stepForm: 'Step Form',
	  table: 'Table',
	  baseTable: 'Base Table',
	  multiHeaderTable: 'Multi Header Table',
	  profile: 'Profile',
	  docs: 'Document',
	  docAppDev: 'App Development',
	  docCli: 'Ufe Cli',
	  docPluggable: 'Extension',
	  docPluggableUI: 'Extension Visualization',
	  resultsPage: {
		title: "Result",
		btnText: "Back to home",
		403: "403 Page",
		404: "404 Page",
		500: "500 Page",
		blank: "No Data",
		staytuned: "Stay Tuned",
		"blank-tip": "No Data",
		"staytuned-tip": "Stay Tuned",
		"403-tip": "Access to this resource is forbidden. Please make sure you have the necessary permissions to access this page.",
		"404-tip": "Sorry, we're unable to process your request at this time.",
		"500-tip": "We're sorry, but there was an error processing your request. Please try again later or contact support for assistance.",
	  },
	  authPage: {
		authCenter: "AuthCenter",
		tenantManagement: "TenantManagement",
		userManagement: "UserManagement",
		roleManagement: "RoleManagement",
		departmentManagement: "DepartmentManage",
		authManagement: "AuthManagement",
		menuManagement: "MenuManagement"
	  },
	},
	navbar: {
	  message: 'Message',
	  themeDefault: 'Default',
	  themeBlue: 'Dawn blue',
	  themeVolcano: 'Volcano',
	  i18n: 'Language',
	  role: 'admin',
	  profile: 'profile',
	  exit: 'log out'
	},
	login: {
	  leftTopContent: 'Log on to',
	  leftBottomContent: 'EDM',
	  title: 'login',
	  logBtn: 'login',
	  remember: 'remember',
	  changepwd: 'Change password'
	},
	message: {
		switchLanguage: 'Switch Language Success'
	},
	changepwd: {
		title: "Change password",
		btn: "Modify and log in"
	},
	loading: {
		login: "login request...",
		changepwd: "Change password and log in..."
	}
}
  