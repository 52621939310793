import languages from "./config";
import { useGlobalStore } from "../store";

export function getLanguage(languages) {
  if(useGlobalStore().lang) return useGlobalStore().lang
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(languages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return "en";
}

export { languages }
