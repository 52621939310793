import Logo from "./logo";
import MainView from "./main-view";
import Navbar from "./navbar";
import ControlTools from "./control-tools";
import Sidebar from "./sidebar";
import Breadcrumb from "./breadcrumb";

const components = [
	Logo,
	MainView,
	Navbar,
    ControlTools,
	Sidebar,
	Breadcrumb
];

const install = (Vue) => {
    components.forEach((component) => {
        Vue.component(component.name, component);
    });
};
export default { install }
