import { defineStore } from 'pinia';

export const useGlobalStore = defineStore("global", {
	state: () => ({
		lang: ''
	}),
	actions: {
		changeGlobalLang(lang) {
			this.lang = lang;
		}
	},
	persisted: true
});
