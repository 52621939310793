<template>
  <div class="drawer-container">
    <div style="z-index: 9999;">
      <el-form :model="form">
        <el-form-item label="店铺">
          <el-select v-model="form.storeId" clearable size="small" style="width: 100%" popper-class="select_popper">
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="handleClickGetData" :loading="loading">数据抓取</el-button>
    </div>
  </div>
</template>

<script>
import { status } from "nprogress";
import API from "../../api/index";
export default {
  name: "setting",
  data() {
    return {
      form: {
        storeId: ""
      },
      storeList: [],
      loading: false,
      timerId: null,
      status: ""
    }
  },
  mounted() {
    //查询店铺数据
    this.searchStoreData();
    this.handleClickGetStatus();
  },
  watch: {
    status(newVal) {
      if (newVal == "2") {
        clearInterval(this.timerId);
      }
    },
  },
  methods: {
    searchStoreData() {
      API.send("queryStore").then(async (res) => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.storeList = res.data;
        console.log(this.storeList);
      })
    },
    handleClickGetData() {
      this.loading = true;
      const req = {
        data: {
          storeId: 2
        }
      }
      this.timerId = setInterval(function () {
        // API.send("statusCompletion").then(async (res) => {
        //   console.log(res);
        //   this.status = res.msg;
        // }).catch((err) => {
        //   this.loading = false;
        //   console.log(err);
        // });
        console.log(111111);
        this.status = "2";
      }, 5000);
      // API.send("updateData", req).then(async (res) => {
      //   console.log(res);
      // }).catch((err) => {
      //   this.loading = false;
      //   console.log(err);
      //   this.$message.error("抓取异常！");
      // });
    },
    handleClickGetStatus() {
      API.send("statusCompletion").then(async (res) => {
        console.log(res);
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.drawer-container {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;


  .drawer-title {
    margin-bottom: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
  }

  ::v-deep .select_popper {
    z-index: 99999 !important;
  }

  .drawer-item {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    padding: 12px 0;
  }

  .drawer-switch {
    float: right
  }
}
</style>
