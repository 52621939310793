<template>
    <div :class="['has-logo', configStore.layout === 'onlyHead' ? 'layout-onlyhead': 'layout-not-onlyhead']">
      <logo v-if="configStore.layout==='onlySide'  " :collapse="isCollapse" />
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <el-menu :default-active="activeMenu" :collapse="isCollapse"
          :unique-opened="false"
          :collapse-transition="false" :mode="mode">
          <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
        </el-menu>
      </el-scrollbar>
    </div>
  </template>
  
  <script>
  import { mapStores } from 'pinia';
  import { useSidebarStore, useConfigStore} from "../../../store";
  import SidebarItem from './sidebarItem'
  
  export default {
    name: "Sidebar",
    components: { SidebarItem },
    computed: {
      ...mapStores(useSidebarStore, useConfigStore),
      mode(){
        return this.configStore.layout === 'onlyHead' ? 'horizontal' : 'vertical';
      },
      routes() {
        return this.$router.options.routes
      },
      activeMenu() {
        const route = this.$route
        const { meta, path } = route
        // if set path, the sidebar will highlight the path you set
        if (meta.activeMenu) {
          return meta.activeMenu
        }
        return path
      },
      isCollapse() {
        return !this.sidebarStore.opened
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  .layout-onlyhead {
    flex: 1;
    overflow: hidden;
    ::v-deep .el-scrollbar {
      & .scrollbar-wrapper {
        overflow-y: hidden;
      }
      & .el-scrollbar__bar.is-vertical {
        display: none;
      }
    }
    ::v-deep .el-menu--horizontal.el-menu {
      background: $--color-primary;
      display: flex;
      flex-wrap: nowrap;
      & .el-menu-item {
        color: $--color-white;
        &.is-active{
          color: $--color-white;
        }
      }
    }
  }
  .layout-not-onlyhead{
    ::v-deep .el-menu--collapse {
      & .el-submenu {
        &>.el-submenu__title{
          padding-left: 20px !important;
          &>span{
            height: 0;
            width: 0;
            overflow: hidden;
            visibility: hidden;
            display: inline-block
          }
          &>.el-submenu__icon-arrow{
            display: none;
          }
        }
      }
    }
  }
  </style>
