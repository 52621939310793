<template>
  <div ref="rightPanel" class="rightPanel-container">
    <div class="rightPanel-background" />
    <div class="rightPanel">
      <div class="handle-button" @click="SettintBtnClick">
        <i class="el-icon-setting" />
      </div>
    </div>

    <el-dialog title="数据抓取" :visible.sync="dialogVisible" width="40%" :close-on-click-modal="false">
      <p v-if="!loading" style="color:#00a66e;">数据抓取已完成</p>
      <p v-if="loading" style="color:coral;">数据抓取中~~~</p>
      <el-form :model="form">
        <el-form-item>
          <el-select v-model="form.storeId" placeholder="请选择店铺" clearable size="small" style="width: 100%">
            <el-option v-for="item in storeList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="handleClickGetData" :loading="loading">数据抓取</el-button>
      <el-button type="primary" @click="handleClickGetStatus" v-if="loading">刷新当前状态</el-button>
      <p>*Tip:选择店铺，点击按钮进行数据抓取后，可点击刷新按钮查看当前爬取状态</p>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../api/index";
export default {
  name: 'RightPanel',
  data() {
    return {
      form: {
        storeId: ""
      },
      storeList: [],
      loading: false,
      timerId: null,
      status: "",
      dialogVisible: false
    }
  },
  mounted() {
  },
  methods: {
    searchStoreData() {
      API.send("queryStore").then(async (res) => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
          return;
        }
        this.storeList = res.data;
      })
    },
    SettintBtnClick() {
      this.searchStoreData();
      this.handleClickGetStatus();
      this.dialogVisible = true;
    },
    handleClickGetData() {
      if (this.form.storeId == "") {
        this.$message({
          type: 'error',
          message: "请先选择店铺！"
        });
        return;
      }
      this.loading = true;
      const req = {
        data: {
          storeId: this.form.storeId
        }
      }
      API.send("updateData", req).then(async (res) => {
        console.log(res);
      }).catch((err) => {
        this.loading = false;
        console.log(err);
        this.$message.error("抓取异常！");
      });
    },
    handleClickGetStatus() {
      API.send("statusCompletion").then(async (res) => {
        if (res.msg == "2") {
          this.loading = true;
        } else if (res.msg == "1") {
          this.loading = false;
        }
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    }
  }
}
</script>

<style>
.showRightPanel {
  overflow: hidden;
  position: relative;
  width: calc(100% - 15px);
}
</style>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0px 20px 20px 20px;
}

.rightPanel-background {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity .3s cubic-bezier(.7, .3, .1, 1);
  background: rgba(0, 0, 0, .2);
  z-index: -1;
}

.rightPanel {
  width: 100%;
  max-width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, .05);
  transition: all .25s cubic-bezier(.7, .3, .1, 1);
  transform: translate(100%);
  background: #fff;
  z-index: 40000;
}

.show {
  transition: all .3s cubic-bezier(.7, .3, .1, 1);

  .rightPanel-background {
    z-index: 20000;
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  .rightPanel {
    transform: translate(0);
  }
}

.handle-button {
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 20px;
  left: -48px;
  text-align: center;
  font-size: 24px;
  border-radius: 6px 0 0 6px !important;
  z-index: 0;
  pointer-events: auto;
  cursor: pointer;
  color: #000000;
  background: #07b6b5;
  line-height: 48px;

  i {
    font-size: 24px;
    line-height: 48px;
  }
}
</style>
