import { defineStore } from 'pinia';

export const useHasMenuStore = defineStore("hasmenu", {
	state: () => ({
		hasMenu: false, //是否从服务端获取菜单了
	}),
	actions: {
		setHasMenu(has) {
			this.hasMenu = has
		}
	}
})
