import { ExtensionCore } from '@ufe/extension-core'
let extensionCore = new ExtensionCore();
extensionCore.active();
if(window.$ufe) {
    window.$ufe.extensionCore = extensionCore
}else {
    window.$ufe = {
        extensionCore
    }
}
export default extensionCore