import { defineStore } from 'pinia';

export const useSidebarStore = defineStore("sidebar", {
	state: () => ({
		opened:true
	}),
	actions: {
		openedSidebar() {
			this.opened = !this.opened;
		}
	}
});
