import Request from "@ufe/pc-pipe-request";
import { apiConf } from "./config.js"; // 接口配置文件

const getToken = function () {
	return sessionStorage.getItem("token");
};

const getTokenType = function () {
	return sessionStorage.getItem("tokenType");
};
import { useUserInfoStore } from "../store";
const requestConf = {
	defaultsConfig: {
		// baseURL: "http://111.230.193.55:8080/"
		baseURL: "/api"
	},
	interceptorsRequestUse(config) {
		// 请求拦截钩子
		if (getToken()) {
			config.headers.token = getToken();
		}	
		return config;
	},
	interceptorsRequestErr(error) {
		// 请求异常拦截钩子
	},
	interceptorsResponseUse(response) {
		// 响应拦截钩子
		if(response.data.msg == "登录过期，重新登录") {
			useUserInfoStore().setUserName("");
            useUserInfoStore().setUserInfo({});
			sessionStorage.removeItem('token');
			window.location.href = '/login';
		}
		if (response.config.interceptors) {
			return response;
		}
		if(response.config.responseType == "blob") {
			return response;
		}
		return response.data;
	},
	interceptorsResponseErr(error) {
		// 响应异常拦截钩子
	},
};
const Api = new Request(requestConf, apiConf);
export default Api;
export { getToken, getTokenType }
