<template>
  <div v-if="!item.hidden" :class="[configStore.layout==='onlyHead' ? 'menu-item-wrapper' : 'wrapper-not-onlyhead']">
    <template
      v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <item-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'submenu-title-noDropdown': !isNest }">
          <i v-if="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :class="[onlyOneChild.meta.icon || (item.meta && item.meta.icon), 'sub-el-icon']"></i>
          <span v-if="onlyOneChild.meta.title" slot='title'>
            {{ generateTitle(onlyOneChild.meta.title) }}
            <el-badge v-bind="onlyOneChild.meta.badge" />
          </span>
        </el-menu-item>
      </item-link>
    </template>

    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title" v-if="item.meta">
        <i v-if="item.meta.icon" :class="[item.meta && item.meta.icon, 'sub-el-icon']"></i>
        <span v-if="item.meta.title">
          {{ generateTitle(item.meta.title) }}
          <el-badge v-bind="item.meta.badge" />
        </span>
      </template>
      <sidebar-item v-for="child in item.children" :key="child.path" :item="child"
        :base-path="resolvePath(child.path)" />
    </el-submenu>
  </div>
</template>

<script>
import ItemLink from './itemLink'
import { generateTitle } from '@/utils/i18n'
import { mapStores } from 'pinia';
  import { useConfigStore } from '../../../store';
export default {
  name: 'SidebarItem',
  computed:{
    ...mapStores(useConfigStore)
  },
  components: { ItemLink },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      let basePath = this.basePath.replace(/\/\:child\*/, "/");
      routePath = routePath.replace(/\:id/, "1")
      return this.combineURL(basePath, routePath)
    },
    combineURL(baseURL, relativeURL) {
      return relativeURL ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '') : baseURL
    },
    generateTitle
  }
}
</script>
<style lang="scss" scoped>
.menu-item-wrapper {
  height: 48px;
  & .sub-el-icon {
    color: $--color-white;
  }
  ::v-deep .el-menu-item {
    height: 48px;
    line-height: 3;
    &:hover {
      background: $--color-primary-active;
    }
  
  }
  ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: $--color-white;
  }
  ::v-deep .el-submenu__title {
    height: 48px;
    line-height: 3;
    color: $--color-white;
    &:hover {
      background: $--color-primary-active;
    }
    & i {
      color: $--color-white;
    }
  }
}
.wrapper-not-onlyhead {
  ::v-deep .el-submenu__title, .el-submenu__title, .el-menu-item {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-submenu {
    .el-submenu__title {
      // padding-left: 5px !important;
    }
    .el-menu-item  {
      text-align: left;
    }
  }
}

::v-deep .el-submenu .el-badge,
.el-menu-item .el-badge{
  min-width: 50px;
  text-align: center;
}
</style>
