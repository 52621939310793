/**
 * @description: 路由，菜单，面包屑，tab标题使用国际化的地方生成国际化文本
 * @param {*} title 路由配置里面的meta.title
 * @return {*} 生成的国家化文本
 */
export function generateTitle(title) {
	const hasKey = this.$te(`route.${title}`)
	if (hasKey) {
	  return this.$t(`route.${title}`)
	}
	return title
}