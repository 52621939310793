<template>
    <div class="navbar">
      <div v-if="configStore.layout==='onlySide'" :class="[sidebarStore.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold']" class="hamburger-container" @click="toggleSideBar" />
      <logo v-else class="logo-container" :collapse="false"></logo>
      <sidebar v-if="configStore.layout==='onlyHead'"/>

      <div class="right-menu">
        <control-tools class="control-tools"></control-tools>
      </div>
    </div>
  </template>
  
  <script>
  
  import { mapStores, mapActions} from 'pinia';
  import { useConfigStore, useSidebarStore} from "../../../store";
  
  export default {
    name: "Navbar",
    computed: {
      ...mapStores(useConfigStore, useSidebarStore),
    },
    methods: {
      ...mapActions(useSidebarStore, ['openedSidebar']),
      toggleSideBar() {
        this.openedSidebar()
      },
      async logout() {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .navbar {
    height: 100%;
    overflow: hidden;
    position: relative;
    background: $--color-white;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);
    padding: 0 20px;
    background: $--color-primary;
    color: $--color-white;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  
    .hamburger-container {
      line-height: 48px;
      height: 100%;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color:transparent;
      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
    .logo-container {
      cursor: pointer;
      width: 150px;
      height: 100%;
    }

    .right-menu {
      height: 100%;
      max-width: 50%;
      white-space: nowrap;
      overflow: hidden;
  
      &:focus {
        outline: none;
      }
  
      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: $--color-text-primary;
        vertical-align: text-bottom;
  
        &.hover-effect {
          cursor: pointer;
          transition: background .3s;
  
          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }
  
      .avatar-container {
        margin-right: 30px;
  
        .avatar-wrapper {
          margin-top: 5px;
          position: relative;
  
          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
  
          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
  </style>
  