<template>
    <div class="dark-color-class" @click="darkChange">
        <i :class="[darkcolor ? 'el-icon-moon-night' : 'el-icon-sunny']"></i>
    </div>
</template>
<script>
export default {
    data() {
        return {
            darkcolor: false
        }
    },
    methods: {
        // 暗色模式切换
        darkChange(){
           let linkDom = document.querySelector(`link[data-theme=dark]`);
           if(this.darkcolor){
            this.$set(this,'darkcolor',false);
            linkDom.setAttribute("disabled", "disabled");
           }else{
            this.$set(this,'darkcolor',true);
            linkDom.removeAttribute("disabled");

           }
        },
    },
    mounted(){
    }
}
</script>
<style lang="scss" scoped>
.dark-color-class {
    width:25px;
    height: 25px;
    flex-shrink: 0;
    margin-left: 16px;
    background-color: $--color-primary-light-7;
    border: 1px solid $--color-white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out 0s ;
    cursor: pointer;
    
    i {
        color: $--color-white;
        font-size: 18px;
        margin-left: 4px;
    }
}
</style>
