<template>
    <div id="app">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>
  
<script>
export default {
    name: 'App',
    mounted() {
    },
}
</script>
  
<style lang="scss">
html, body, #app {
	margin: 0;
	padding: 0;
	height: 100%;
}
#app {
    font-family: 'SourceSansPro','SourceHanSansCN','sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $--color-text-regular;
    overflow: hidden;
}
#app .el-input__inner {
    font-family: 'SourceSansPro','SourceHanSansCN','sans-serif';
}
</style>
  