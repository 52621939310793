<template>
  <div>
    <el-dropdown @command="toUserView" placement="bottom">
      <span class="el-dropdown-link">
        <el-avatar
          class="login-user-icon"
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
        <span>{{ userInfoStore.userName }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="user in userCenter"
          :key="user.value"
          :command="user.value"
        >
          <span class="user-item">
            <i :class="user.icon"></i>
            {{ $t(user.label) }}
          </span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import API from '../../../../api';
import { mapActions, mapStores, mapWritableState } from "pinia";
import {
  useGlobalStore,
  useUserInfoStore,
  useTabsViewStore,
  useHasMenuStore
} from "../../../../store";
export default {
  name: "profile",
  data() {
    return {
      userCenter: [
        {
          label: "navbar.exit",
          value: "/login",
          icon: "el-icon-s-release",
        },
      ],
    };
  },
  computed: {
    ...mapStores(useGlobalStore, useUserInfoStore),
    ...mapWritableState(useTabsViewStore, ["visitedViews"]),
  },
  methods: {
    ...mapActions(useUserInfoStore, ["setUserName","setUserInfo"]),
    ...mapActions(useHasMenuStore, ["setHasMenu"]),
    async toUserView(path) {
      if (path === "/login") {
        await API.send('logout');

        //重置用户信息&签页信息
        this.setUserName("");
        this.setUserInfo({});
        this.visitedViews = [];
          
        //清除localstorage中的token,tokenType
        sessionStorage.removeItem('token');
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  color: $--color-white;
}
.login-user-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
