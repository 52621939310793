import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import persistedState from "@ufe/pc-pipe-pinia-persistedstate"
import { useUserInfoStore } from './userInfo'
import { useGlobalStore } from './global'
import { useConfigStore } from './config'
import { useSidebarStore } from './sidebar'
import { useTabsViewStore } from './tabsview'
import { useHasMenuStore } from './hasmenu'

Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(persistedState(false))

useUserInfoStore(pinia);
useGlobalStore(pinia);
useConfigStore(pinia);
useSidebarStore(pinia);
useTabsViewStore(pinia);
useHasMenuStore(pinia)

export default pinia;

export {
	useUserInfoStore,
    useGlobalStore,
    useConfigStore,
    useSidebarStore,
    useTabsViewStore,
	useHasMenuStore
}
