<template>
  <el-dropdown trigger="click" placement="bottom" class="international" @command="handleSetLanguage">
    <div>
      <el-tooltip :content="$t('navbar.i18n')">
        <span class="languageLabel">{{ languages[lang].languageLabel }}</span>
      </el-tooltip>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-for="lang of Object.keys(languages)" :key="lang" :disabled="language === `${lang}`" :command="`${lang}`">
        {{languages[lang].languageLabel}}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useGlobalStore } from "../../../../store";
import { languages } from "@/lang/index";
export default {
  name: 'language',
  data() {
    return {
      languages
    }
  },
  computed: { 
    ...mapState(useGlobalStore, ['lang']),
    language() {
      return this.lang
    }
  },
  methods: {
    ...mapActions(useGlobalStore, ['changeGlobalLang']),
    handleSetLanguage(lang) {
      this.changeGlobalLang(lang);
			this.$i18n.locale = lang;
      this.$message({
        message: this.$t('message.switchLanguage'),
        type: 'success'
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.languageLabel{
  color: $--color-white;
}
</style>
