export default {
	route: {
	  rootMenu: "主应用菜单",
	  home: "首页",
	  dashboard: '主页',
	  form: '表单页',
	  baseForm: '基础表单页',
	  stepForm: '分步表单页',
	  table: '表格页',
	  baseTable: '基础表格页',
	  multiHeaderTable: '多表头表格页',
	  profile: '个人中心',
	  docs: '文档中心',
	  docAppDev: '应用开发',
	  docCli: '脚手架',
	  docPluggable: '可插拔',
	  docPluggableUI: '可插拔可视化',
	  resultsPage: {
		title: "结果页",
		btnText: "回到首页",
		403: "403 页面",
		404: "404 页面",
		500: "500 页面",
		blank: "暂无数据页面",
		staytuned: "敬请期待页面",
		"blank-tip": "暂无数据",
		"staytuned-tip": "敬请期待",
		"403-tip": "禁止访问该资源，请确保您拥有访问该页面所需的权限",
		"404-tip": "抱歉，目前无法处理您的请求",
		"500-tip": "很抱歉，处理您的请求时出错，请稍后重试或联系支持人员寻求帮助",
	  },
	  authPage: {
		authCenter: "认证中心",
		tenantManagement: "租户管理",
		userManagement: "用户管理",
		roleManagement: "角色管理",
		departmentManagement: "部门管理",
		authManagement: "权限管理",
		menuManagement: "菜单管理"
	  }
	},
	navbar: {
	  message: '消息',
	  themeDefault: '默认主题',
	  themeBlue: '拂晓蓝',
	  themeVolcano: '火山',
	  i18n: '语言',
	  role: '管理员',
	  profile: '个人中心',
	  exit: '退出登录'
	},
	login: {
	  leftTopContent: '登录到',
	  leftBottomContent: 'EDM后台管理系统',
	  title: '登录',
	  logBtn: '登录',
	  remember: '记住账号',
	  changepwd: '修改密码'
	},
	message: {
		switchLanguage: '语言切换成功'
	},
	changepwd: {
		title: "修改密码",
		btn: "修改并登录"
	},
	loading: {
		login: "请求中...",
		changepwd: "修改密码并登录..."
	}
}
  