import { defineStore } from 'pinia';

function getPath(view) {
	let viewPath = view.path;
	if(view.matched && view.matched[0].path.indexOf(":child*")!=-1){
		viewPath = view.matched[0].path.replace(/\:child\*/, "")
	}
	return viewPath
}

export const useTabsViewStore = defineStore("tabsView", {
    state: () => ({
        visitedViews: []
    }),
    actions: {
        addVisitedView(view) {
            let viewPath = getPath(view);
			
			if (this.visitedViews.some(v => getPath(v) === viewPath)) return
            this.visitedViews.push(
                Object.assign({}, view, {
                    title: view.meta.title || 'no-name'
                })
            )
        },
        delVisitedView(view) {
            return new Promise(resolve => {
                for (const [i, v] of this.visitedViews.entries()) {
                    if (v.path === view.path) {
                        this.visitedViews.splice(i, 1)
                        break
                    }
                }
                resolve({
                    visitedViews: [...this.visitedViews],
                })
            })
        },
    },
	persisted:false
});

