import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { buildTree, generateMenuRoutes } from '../utils/processDynamicRoute'
import API from "../api"
import { resetRouter } from './index'
import { useUserInfoStore, useConfigStore, useHasMenuStore } from "../store"
let userInfoStore = useUserInfoStore()
let configStore = useConfigStore()
let hasMenuStore = useHasMenuStore()

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const routes = [
	{
		path: "/redirect",
		component: () => import("@/layout/index"),
		hidden: true,
		children: [
			{
				path: "/redirect/:path(.*)",
				component: () => import("@/views/redirect/index"),
			},
		],
	},
	{
		path: "/login",
		name: "Login",
		component: () => import(`@/views/login/index`),
		hidden: true,
	},
	{
		path: "/changepwd/:username?",
		component: () => import("@/views/password/changepwd"),
		hidden: true,
	},
	{
		path: "/",
		component: () => import("@/layout/index"),
		redirect: "/login",
		children: [
			{
				path: "dashboard",
				name: "Dashboard",
				component: () => import(`@/views/${configStore.homePage}`),
				meta: {
					title: "dashboard",
					icon: "el-icon-s-home",
					affix: true,
				},
			},
		],
	},
	{
		path: "/user",
		component: () => import("@/layout/index"),
		redirect: "/user",
		hidden: userInfoStore.userInfo.auth != '9' && userInfoStore.userInfo.auth != '0',
		children: [
			{
				path: "index",
				name: "UserManagementInfo",
				component: () => import("@/views/userManagement/index"),
				meta: {
					title: "用户管理",
					icon: "el-icon-s-custom"
				},
			},
		],
	},
	{
		path: "/store",
		component: () => import("@/layout/index"),
		redirect: "/store",
		hidden: userInfoStore.userInfo.auth != '0' && userInfoStore.userInfo.auth != '9',
		children: [
			{
				path: "index",
				name: "StoreManagementInfo",
				component: () => import("@/views/storeManagement/index"),
				meta: {
					title: "店铺管理",
					icon: "el-icon-s-shop"
				},
			},
		],
	},
	{
		path: "/product",
		component: () => import("@/layout/index"),
		redirect: "/product",
		hidden: userInfoStore.userInfo.auth == '3',
		children: [
			{
				path: "index",
				name: "ProductManagementInfo",
				component: () => import("@/views/productManagement/index"),
				meta: {
					title: "商品管理",
					icon: "el-icon-s-goods"
				},
			},
		],
	},
	{
		path: "/stock",
		component: () => import("@/layout/index"),
		redirect: "/stock",
		hidden: userInfoStore.userInfo.auth == '2',
		children: [
			{
				path: "index",
				name: "StockManagementInfo",
				component: () => import("@/views/StockManagement/index"),
				meta: {
					title: "备货管理",
					icon: "el-icon-s-management"
				},
			},
		],
	},
	{
		path: "/404",
		component: () => import("@/views/results/404"),
		hidden: true,
	},
	{
		path: "*", redirect: "/404", hidden: true,
	}
];

//路由钩子
const beforeEach = (router) => {
	return async (to, from, next) => {
		try {
			NProgress.start()
			//没有登录用户信息
			if (!userInfoStore.userName) {
				if (to.path !== "/login" && to.path !== "/changepwd") {
					next({
						name: "Login"
					})
				} else {
					next()
				}
			} else {
				if (to.path == "/login") {
					next({
						name: "Dashboard"
					})
				} else if (to.path === '/404' && to.redirectedFrom.includes('auth-center') && hasMenuStore.hasMenu) {
					await next({ path: to.redirectedFrom })
				} else {
					next()
				}
			}
			NProgress.done()
		} catch (error) {
			console.log("拦截路由进入之前出错", error);
		}
	}
}

const afterEach = (to, from) => {
	try {
		NProgress.done()
	} catch (error) {
		console.log("拦截路由进入之后出错", error)
	}
}

export { routes, beforeEach, afterEach }
