import Vue from 'vue'
import Router from 'vue-router'
import { routes, beforeEach, afterEach } from './config'

Vue.use(Router)

// fix push & replace error
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.push;
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Router.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
const createRouter = (constantRoutes) => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})

const resetRouter = (router) => {
	router.matcher = new Router().matcher;
}

window.$ufe.extensionCore.register("routerAfterEach", {
	data: [afterEach]
})
export { createRouter, beforeEach, routes, resetRouter }
