export function buildTree(data) {
	let idMap = {};
	let rootNodes = [];

	// 创建id映射
	data.forEach((node) => {
	  idMap[node.id] = node;
	});

	data.forEach((node) => {
	  if (node.parentId) {
		// 如果有父节点，添加到父节点的children数组中
		let parentNode = idMap[node.parentId];
		if (parentNode) {
		  !parentNode.children && (parentNode.children = []);
		  parentNode.children.push(node);
		}
	  } else {
		// 如果没有父节点，添加到根节点数组中
		rootNodes.push(node);
	  }
	});

	return rootNodes.sort((a, b) => {
	  return parseInt(a.order, 10) - parseInt(b.order, 10)
	});
}

export function generateMenuRoutes(data) {
	let menuRoutes = [];
	data.forEach(item => {
	  let obj = {}
	  if(item.extensions) {
		obj.meta = {}
		let extensions = JSON.parse(item.extensions)
		obj.path = extensions.path
		obj.meta.title = extensions.title
		extensions.name && (obj.name = extensions.name)
		item.icon && (obj.meta.icon = item.icon)
		if(item.path) obj.component = () => import(`@/${item.path}`);
		if(item.children?.length > 0) {
		  obj.children = generateMenuRoutes(item.children)
		}
		menuRoutes.push(obj)
	  }
	})
	return menuRoutes
}