import Vue from 'vue';
import VueI18n from "vue-i18n";
import ElementUI from 'element-ui';
import App from './App.vue';
import customize from "./components";
import { useConfigStore, useGlobalStore } from './store'
import { getLanguage, languages } from './lang'
import { _importExtension, runRoutesHook, getExtensionRoutes } from '@/extension/regist';
import '@ecy/ecy-block-auth-center/lib/expose.css'
import { createRouter, beforeEach as beforeEachHook, routes as defaultRoutes } from './router'
import store from './store'
import '@/styles/index.scss'
import './styles/base.css';

const main = async(props={}) => {
	let config = {}

    try {
        const response = await fetch(`${__webpack_public_path__}project.config.json`)
        config = await response.json()
    } catch (err) {
        console.log("读取配置文件发生错误:", err, err.stack);
    }

	const { extensions, defaultTheme, defaultLang, defaultLayout, defaultHome, logo, title } = config 

	if(Array.isArray(extensions) && extensions.length > 0) {
        for(let etx of extensions) {
            await _importExtension(etx)
        }
    }

	const globalStore = useGlobalStore()
    if(!globalStore.lang) {
        //设置默认语言
        globalStore.changeGlobalLang(defaultLang)
    }

    const configStore = useConfigStore()
	configStore.setLogo(logo)
	configStore.setLayout(defaultLayout)
    configStore.setHomePage(defaultHome)

    Object.keys(languages).map(key => {
        languages[key].navbar.title = title[key]
    })


	Vue.use(customize);
	Vue.use(VueI18n);
    const i18n = new VueI18n({
        locale: getLanguage(languages),
        messages: languages,
    });
	Vue.use(ElementUI, {
        i18n: (key, value) => i18n.t(key, value)
    })

	Vue.config.productionTip = false;

	const extensionRoutes = getExtensionRoutes();
    const constantRoutes = defaultRoutes.concat(extensionRoutes);
    const router = createRouter(constantRoutes);
	window.$ufe.extensionCore.register("routerBeforeEach", {
		data: beforeEachHook(router)
	});
	runRoutesHook(router)

	new Vue({
        store,
        router,
        i18n,
        render: h => h(App)
    }).$mount('#app');
}



main()

