import elementEnLocale from "element-ui/lib/locale/lang/en"; 
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import enLocale from "./en";
import zhLocale from "./zh";
const languages = {
	en: {
	  ...enLocale,
	  ...elementEnLocale,
	  languageLabel: 'English'
	},
	zh: {
	  ...zhLocale,
	  ...elementZhLocale,
	  languageLabel: '中文'
	}
};

export default languages
