import { render, staticRenderFns } from "./sidebarItem.vue?vue&type=template&id=8a34e57a&scoped=true&"
import script from "./sidebarItem.vue?vue&type=script&lang=js&"
export * from "./sidebarItem.vue?vue&type=script&lang=js&"
import style0 from "./sidebarItem.vue?vue&type=style&index=0&id=8a34e57a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a34e57a",
  null
  
)

export default component.exports