import { defineStore } from 'pinia';

export const useConfigStore = defineStore("config", {
	state: () => ({
		logo: '',
		layout: '',
		loginPage: '',
		homePage: ''
	}),
	actions: {
		setLogo(logo) {
            this.logo = logo
        },
		setLayout(layout){
			this.layout = layout
		},
		setLoginPage(login) {
			this.loginPage = login
		},
		setHomePage(home) {
			this.homePage = home
		}
	},
	persisted: false
});
