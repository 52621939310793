import "./extension";
import "@ufe/pc-pipe-router-extension";
import Layout from '@/layout/index';


async function findEntrance(name) {	
	try {
		let res = await fetch(`${__webpack_public_path__}extensions/${name}/package.json`);
		let pkgJson = await res.json();
		if(!pkgJson.main) throw new Error(`${name}包没有入口文件`)
		return pkgJson.main
	} catch (err) {
		console.log(`读取${name}的package.json失败:`, err, err.stack);
	}
}

export async function _importExtension(name) {
	try {
		let entry = await findEntrance(name);
		return await window.systemjs.import(`${__webpack_public_path__}extensions/${name}/${entry}`).then(res => {
			return res.default
		})
	} catch (err) {
		console.log(`引入扩展${name}失败:`, err, err.stack);
	}
}

export function getExtensionRoutes() {
	let extensionRoutes = window.$ufe.extensionCore.getExtensionsByTag(
		"routes", 
		"normal"
	).reduce((acc, cur) => {
        return acc.concat(...cur.data)
    },[]);
	extensionRoutes.forEach(route => {
        if(!route.component && route.children?.length > 0) {
            route.component = Layout
        }
    })
	return extensionRoutes
}


// router hook extension
export function runRoutesHook(router) {
	let beforeEachHooks = window.$ufe.extensionCore.getExtensions('routerBeforeEach').reduce((acc, cur) => {
        if(Array.isArray(cur.data)){
			acc.concat(...cur.data)
		}else{
			acc.push(cur.data)
		}
		return acc
    },[]);
	let afterEachHooks = window.$ufe.extensionCore.getExtensions('routerAfterEach').reduce((acc, cur) => {
        if(Array.isArray(cur.data)){
			acc.concat(...cur.data)
		}else{
			acc.push(cur.data)
		}
		return acc
    },[]);
	beforeEachHooks.forEach(guard => {
		return router.beforeEach(guard)
	});
	afterEachHooks.forEach(guard => {
		return router.afterEach(guard)
	});
}
