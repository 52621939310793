//登录
const login = {
    method: "post",
    url: '/api/user/login',
    headers: {
        "Content-Type": "application/json",
    },
};

//登出
const logout = {
    method: "post",
    url: '/api/user/logout',
    headers: {
        "Content-Type": "application/json",
    },
};

//修改密码
const updatePwd = {
    method: "post",
    url: '/api/user/updatePwd',
    headers: {
        "Content-Type": "application/json",
    },
};

//查询所有用户
const getAllUser = {
    method: "post",
    url: '/api/user/getAllUser',
    headers: {
        "Content-Type": "application/json",
    },
};

//新增用户
const register = {
    method: "post",
    url: '/api/user/register',
    headers: {
        "Content-Type": "application/json",
    },
};

//删除用户
const deleteUser = {
    method: "post",
    url: '/api/user/delete',
    headers: {
        "Content-Type": "application/json",
    },
};

// --- 店铺管理 api ----- 
//查询全部店铺
const queryStore = {
    method: "post",
    url: '/api/stores/query',
    headers: {
        "Content-Type": "application/json",
    },
};

//分页查询店铺
const storePageInfo = {
    method: "post",
    url: '/api/stores/storePageInfo',
    headers: {
        "Content-Type": "application/json",
    },
};

//新增店铺
const addStore = {
    method: "post",
    url: '/api/stores/add',
    headers: {
        "Content-Type": "application/json",
    },
};

//删除店铺
const deleteStore = {
    method: "post",
    url: '/api/stores/delete',
    headers: {
        "Content-Type": "application/json",
    },
};

//修改店铺
const updateStore = {
    method: "post",
    url: '/api/stores/update',
    headers: {
        "Content-Type": "application/json",
    },
};

//获取商品列表
const getProductList = {
    method: "post",
    url: '/api/products/list',
    headers: {
        "Content-Type": "application/json",
    },
};

//添加商品备注
const addProRemark = {
    method: "post",
    url: '/api/products/addProRemark',
    headers: {
        "Content-Type": "application/json",
    },
};

//修改类目
const modifyItem = {
    method: "post",
    url: '/api/products/modifyItem',
    headers: {
        "Content-Type": "application/json",
    },
};

//修改厂内ID
const modifyPlantId = {
    method: "post",
    url: '/api/stock/modifyPlantId',
    headers: {
        "Content-Type": "application/json",
    },
};

//修改生产状态
const modifyProdStatus = {
    method: "post",
    url: '/api/stock/modifyProdStatus',
    headers: {
        "Content-Type": "application/json",
    },
};

//获取备货商品列表
const getstockList = {
    method: "post",
    url: '/api/stock/stockList',
    headers: {
        "Content-Type": "application/json",
    },
};

//获取备货单备注
const addStockRemark = {
    method: "post",
    url: '/api/stock/addRemark',
    headers: {
        "Content-Type": "application/json",
    },
};

//上传文件
const uploadFile = {
    method: "post",
    url: '/api/files/upload',
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

//查询文件
const findFilesBySkcId = {
    method: "post",
    url: '/api/files/findFilesBySkcId',
    headers: {
        "Content-Type": "application/json",
    },
};

//下载文件
const downloadFile = {
    method: "post",
    url: '/api/files/download',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//导出pdf
const exportPdf = {
    method: "post",
    url: '/api/pdf/exportPdf',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//根据查询条件导出pdf
const generatePdfByCondition = {
    method: "post",
    url: '/api/pdf/generatePdfByCondition',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//删除文件
const deleteFile = {
    method: "post",
    url: '/api/files/deleteFile',
    headers: {
        "Content-Type": "application/json",
    },
};

//导出商品文件
const exportProductExcel = {
    method: "post",
    url: '/api/products/exportExcel',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//根据筛选条件导出商品文件
const exportProductExcelByCondition = {
    method: "post",
    url: '/api/products/exportExcelByCondition',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//导出备货文件
const exportStockExcel = {
    method: "post",
    url: '/api/stock/exportExcel',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//根据筛选条件导出备货文件
const exportStockExcelByCondition = {
    method: "post",
    url: '/api/stock/exportExcelByCondition',
    responseType: "blob",
    headers: {
        "Content-Type": "application/json",
    },
};

//更新数据
const updateData = {
    method: "post",
    url: '/api/craw/updateData',
    headers: {
        "Content-Type": "application/json",
    },
};

//检查更新状态
const statusCompletion = {
    method: "post",
    url: '/api/craw/statusCompletion',
    headers: {
        "Content-Type": "application/json",
    },
};

export const apiConf = {
    login,
    logout,
    updatePwd,
    getAllUser,
    register,
    deleteUser,
    queryStore,
    storePageInfo,
    addStore,
    deleteStore,
    updateStore,
    getProductList,
    getstockList,
    addProRemark,
    modifyItem,
    addStockRemark,
    uploadFile,
    exportPdf,
    findFilesBySkcId,
    downloadFile,
    generatePdfByCondition,
    deleteFile,
    exportProductExcel,
    exportStockExcel,
    exportStockExcelByCondition,
    exportProductExcelByCondition,
    updateData,
    statusCompletion,
    modifyPlantId,
    modifyProdStatus
};
