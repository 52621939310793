<template>
    <div class="control-tools" ref="tools">
        <div v-for="(item,index) in tools" :key="index" :class="`tool-${index}`">
            <component 
                :is="item.component"
                v-bind="item.props"
            ></component>
        </div>
    </div>
</template>

<script>
import language from './components/language.vue';
import profile from './components/profile.vue';
import more from './components/more.vue';
import dark from './components/dark.vue'
export default {
    name: 'ControlTools',
    data() {
        return {
            tools: [
                {
                    component: language
                },    
                {
                    component: profile
                },
                {
                    component: dark
                }
            ],
            observer: null,
            isOverflow: false,
            overflowComponents: [],
            toolLength: 0
        }
    },
    watch: {
        isOverflow(newVal) {
            if(newVal) {
                this.$nextTick(() => {
                    this.calculateOverflowComponent()
                })
            }
        },
        tools: {
            deep: true,
            immediate: true,
            handler(newV) {
                this.toolLength = newV.length;
            }
        },
        toolLength(newV,oldV) {
            if(this.overflowComponents.length > 0 && newV < oldV && !this.isOverflow) {
                let component = this.overflowComponents.pop();
                this.tools.splice(1, 0, component);
                this.calculateOverflowComponent()
            }
        }
    },
    methods: {
        loadExtensionNavbar() {
            let extensionNavbar;
            try {
                extensionNavbar = window.$ufe.extensionCore.getExtensions('navbar').reduce((acc, cur) => {
                    return acc.concat(cur.data)
                },[]);
            } catch (error) {
                //没有注册navbar扩展点直接return
                if(error?.message?.includes('navbar 扩展点不存在！')) return
            }
            const maxIndex = this.tools.length + extensionNavbar.length - 1;
            extensionNavbar.forEach(item => {
                if(item.index < 0) throw new Error('index不能小于0')
                if(item.index > maxIndex) throw new Error('index不合法')
                this.tools.splice(this.isOverflow ? item.index + 1 : item.index, 0 , { component: item.component })
            });
        },
        initObserver() {
            this.observer = new MutationObserver((mutationsList) => {
                const toolbar = this.$refs.tools.parentNode;
                const hasMore = this.tools.findIndex(i => i.component.name === 'more') !== -1;
                if(toolbar.scrollWidth > toolbar.clientWidth) {
                    this.isOverflow = true;
                    !hasMore && this.tools.unshift({ component: more, props: {overflowComponents: this.overflowComponents} })
                }else {
                    this.isOverflow = false;
                    hasMore && this.overflowComponents.length === 0 && this.tools.shift()
                }
            })
        },
        calculateOverflowComponent() {
            //第一个40---左右padding各20, clientWith/2---maxwidth:50%,, 第二个40---三个点图标的宽度
            const toolbarMaxWidth = (this.$refs.tools.parentNode.parentNode.clientWidth) / 2 - 40 - 40;
            let children = this.$refs.tools.childNodes;
            let accumulateWidth = 0;
            for (let i = children.length - 1; i >= 0; i--) {
                if(accumulateWidth + children[i].clientWidth > toolbarMaxWidth) {
                    let overflowIndex = parseInt(children[i].className.split('-')[1]);
                    this.overflowComponents.push(...this.tools.slice(1, overflowIndex + 1))
                    this.tools.splice(1, overflowIndex)
                    break;
                } else {
                    accumulateWidth += children[i].clientWidth
                }
            }
            children = null;
        }
    },
    mounted() {
        this.initObserver();
        const toolbar = this.$refs.tools.parentNode;
        const config = { attributes: true, childList: true, subtree: true };
        this.observer.observe(toolbar, config);

        //加载扩展的navbar
        this.loadExtensionNavbar();
    },
    beforeDestroy() {
        this.observer.disconnect()
    }
}
</script>

<style lang="scss" scoped>
.control-tools {
    display: flex;
    align-items: center;
    height: 100%;

    > * {
        padding: 2px 12px;
        cursor: pointer;

        &:hover {
            background: $--color-primary-active;
            border-radius: 5px;
        }
    }
}
</style>
