<template>
    <section class="main-wrapper">
        <el-header v-if="configStore.layout === 'onlySide' " height="48px">
            <navbar />
        </el-header>
        <TabsView class="tabs-container"></TabsView>
        <section class="main">
                <div class="main-content">
                    <transition name="fade-transform" mode="out-in">
                        <router-view :key="key" />
                    </transition>
                </div>
        </section>
    </section>
</template>
  
<script>
import { mapStores } from 'pinia';
import { useConfigStore } from "../../../store";
import TabsView from './tabsView'
export default {
    name: 'MainView',
    components: {
        TabsView
    },
    computed: {
        ...mapStores(useConfigStore),
        key() {
            return this.$route.path
        },
    }
}
</script>
  
<style lang="scss" scoped>
.main-wrapper {
    height:100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    & .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 10px;
        flex:1;
        overflow: hidden;
        & .main-content {
            flex: 1;
            background: $--color-white;
            border: 1px solid $--border-color-base;
            border-top: none;
            border-radius: 0 0 5px 5px;
            overflow-y: auto;
        }
    }
}
</style>
