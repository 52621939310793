import { defineStore } from 'pinia';

export const useUserInfoStore = defineStore("userInfo", {
	state: () => ({
		userName: '', // 用户名
		isSuper: false, //当前用户是否是超级管理员
		userInfo: {}
	}),
	actions: {
		setUserName(name) {
			this.userName = name;
		},
		setUserInfo(obj) {
			this.userInfo = obj;
		}
	},
	persisted: true
});
