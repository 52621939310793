<template>
  <el-popover
    placement="bottom"
    trigger="hover"
  >
    <div class="more">
      <div
        class="more-header"
        v-for="(item, index) in overflowComponents"
        :key="index"
      >
        <component :is="item.component" v-bind="item.props"></component>
      </div>
    </div>

    <div slot="reference">
      <span><i class="el-icon-more"></i></span>
    </div>
  </el-popover>
</template>

<script>
export default {
  name: "more",
  props: {
    overflowComponents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.more {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: $--color-primary;
  color: white;
  max-width: 300px;
  margin: -6px;

  > * {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background: $--color-primary-active;
      border-radius: 5px;
    }
  }
}
</style>
