<template>
  <el-container v-if="configStore.layout === 'onlySide'" class="app-wrapper">
    <el-aside :width="this.sidebarStore.opened ? '200px' : ''">
      <sidebar class="sidebar-container" />
    </el-aside>
    <el-main>
      <main-view />
    </el-main>
    <!-- 置顶 -->
    <div class="goTop" @click.capture="goTop">
      <i class="el-icon-top" style="font-size: 28px;color:#000"></i>
    </div>
    <right-panel v-if="auth === '9' || auth === '1' || auth === '0'">
    </right-panel>
  </el-container>
  <el-container v-else class="app-wrapper">
    <el-header height="48px">
      <navbar />
    </el-header>
    <el-container class="context-wrapper">
      <el-aside v-if="configStore.layout === 'head'" width="200px">
        <sidebar />
      </el-aside>
      <el-main>
        <main-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import { mapStores } from 'pinia';
import { useConfigStore, useSidebarStore, useUserInfoStore } from "../store";
import settings from "./components/settings.vue";
import RightPanel from '@/components/RightPanel'

export default {
  components: { settings, RightPanel },
  name: 'Layout',
  computed: {
    ...mapStores(useConfigStore),
    ...mapStores(useSidebarStore),
    classObj() {
      return {
        hideSidebar: !this.sidebarStore.opened,
        openSidebar: this.sidebarStore.opened
      }
    },
  },
  mounted() {
    this.auth = useUserInfoStore().userInfo.auth;
  },
  data() {
    return {
      auth: ""
    }
  },
  methods: {
    //点击置顶
    goTop() {
      const scrollEle = document.querySelector('#product');
      scrollEle.scrollIntoView({ behavior: "smooth" })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;

  .context-wrapper {
    overflow: hidden;
  }
}

.goTop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: 73px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  background: #07b6b5;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

::v-deep .el-header {
  padding: 0;
}

::v-deep .el-main {
  padding: 0;
  background-color: $--color-active-background;
}

::v-deep .el-aside {
  overflow: hidden;

  .has-logo {
    height: 100%;
    background: $--color-meun-background;

    .el-scrollbar {
      height: 100%;

      .el-scrollbar__wrap {
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;

        .el-scrollbar__view {
          height: 100%;

          .el-menu {
            height: 100%;
            background: $--color-meun-background;
            border: none;
          }
        }
      }
    }
  }
}
</style>
