<template>
    <div class="logo-container" :class="{ 'collapse': collapse }">
      <transition name="logoFade">
        <router-link key="collapse" class="logo-link" to="/">
          <img :src="configStore.logo" class="logo">
          <h1 v-if="!collapse" class="title">{{ $t('navbar.title') }} </h1>
        </router-link>
      </transition>
    </div>
  </template>
  
  <script>
  import { mapStores } from 'pinia';
  import { useConfigStore } from '../../../store';
  export default {
    name: 'Logo',
    computed: {
        ...mapStores(useConfigStore)
    },
    props: {
      collapse: {
        type: Boolean,
        required: true
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .logoFade-enter-active {
    transition: opacity 1.5s;
  }
  
  .logoFade-enter,
  .logoFade-leave-to {
    opacity: 0;
  }
  
  .logo-container {
    position: relative;
    width: 100%;
    text-align: center;
    overflow: hidden;
    & .logo-link {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
  
      & .logo {
        width: 32px;
        height: 32px;
        vertical-align: middle;
        margin-right: 12px;
      }
  
      & .title {
        display: inline-block;
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        color: $--color-white;
        font-family: 'SourceSansPro','SourceHanSansCN','sans-serif';
        vertical-align: middle;
      }
    }
  
    &.collapse {
      padding-left: 10px;
      .logo {
        margin-right: 0px;
      }
    }
  }
  </style>
  